<template>
  <v-app>
    <v-main>
      <HelloWorld/>
      <sec-section/>
      <search-sec/>
      <auto-coupon/>
      <download-coupons/>
    </v-main>
  </v-app>
</template>

<script>
import AutoCoupon from '../components/AutoCoupon.vue'
import DownloadCoupons from '../components/DownloadCoupons.vue'
import HelloWorld from '../components/HelloWorld'
import SearchSec from '../components/SearchSec.vue'
import SecSection from '../components/SecSection.vue'

export default {
  name: 'App',

  components: {
    HelloWorld,
    SecSection,
    SearchSec,
    AutoCoupon,
    DownloadCoupons
  },

  data: () => ({
    //
  })
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300&display=swap');
.theme--light.v-application.v-application {
   background-color:rgb(229, 250, 255);
    color: rgba(0, 0, 0, 0.87);
}
.Mytitle{
    font-family: 'Cairo' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 75px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    word-spacing: -1px;
    text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
    margin-bottom: 0px;
    margin-top: 40px;
}
.my-text-h3{
  font-size: 3rem !important;
    font-weight: 400;
    line-height: 3.125rem;
    letter-spacing: normal !important;
    font-family: "Cairo", sans-serif !important;
}
.my-text-h6{
  font-family: 'Cairo', sans-serif !important;
  font-size: 1.25rem !important;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em !important;
}
.my-text-h4{
  font-family: 'Cairo', sans-serif !important;
  font-size: 2.125rem !important;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.0073529412em !important;
}
.text{
        width: 300px;
        text-align: center;
        font-family: 'Cairo', sans-serif !important;
        color: #838282;
        font-size: 22px;
        font-weight: 500;
}

@media (max-width: 768px) {
  p {
    font-size: 1.25rem !important;
  }
}
</style>
