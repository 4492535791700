<template>
  <div :class="{'px-16': $vuetify.breakpoint.mdAndUp}" class="mt-15 mb-16" >
    <v-row align="center" align-content="center" justify="center"  >
      <v-col :class="{'col-12': $vuetify.breakpoint.smAndDown, 'col-7': $vuetify.breakpoint.mdAndUp}" class="d-flex justify-center align-center">
        <img src="../assets/search.gif" alt="" width="50%" style="border: 1px black solid; border-radius: 20px; z-index: 1;">
      </v-col>
      <v-col :class="{'col-12': $vuetify.breakpoint.smAndDown, 'col-5': $vuetify.breakpoint.mdAndUp}" class="rtl text-center d-flex justify-center align-center flex-column" style="width: 100%;">
        <img src="../assets/auto.svg" style="position: absolute;" alt="" width="70%" class="hidden-sm-and-down">
        <img src="../assets/searchfield.svg" width="40%" style="z-index: 1;" class="mb-3">
        <p class="my-text-h4 sm-width countryText " style=" z-index: 1;" :class="{'px-10': $vuetify.breakpoint.smAndDown, '': $vuetify.breakpoint.mdAndUp}"  > ابحث عن متجرك المفضل واحصل على الخصومات عند الشراء</p>
        <p class="my-text-h6 countryText px-16" style=" z-index: 1;">
          اختصر الوقت والجهد لمعرفة المتاجر التي ندعمها, ابحث عنها وستجد كوبونات الخصم الخاصة بك متوفرة وبحسومات مميزة
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.sm-width{
  width: 70%;
}
@media (max-width: 768px) {
  .sm-width{
  width: 100%;
}
}
</style>
