<template>
  <div class="mt-15"  :class="{'px-16': $vuetify.breakpoint.mdAndUp}">
    <v-row>
      <v-col class="col-12">
        <h1 class="text-center my-text-h3 countryTextBlack px-4 mb-5" style="margin-top: 200px;"> ماذا تقدم لك إضافة بوابة الكوبونات </h1>
      </v-col>
    </v-row>
      <v-row class=" text-center"  :class="{'flex-column-reverse': $vuetify.breakpoint.smAndDown}" style="display: flex;
              flex-wrap: wrap;
              flex: 1 1 auto;
              margin: -12px;
              justify-content: center;
              align-items: center;
              align-content: center;">
        <v-col class="d-flex algin-center justify-center text-center flex-column mt-16" style="justify-content: center; align-items: center; align-self: center; width: 310px !important;"  :class="{'col-12': $vuetify.breakpoint.smAndDown, 'col-6': $vuetify.breakpoint.mdAndUp}">
          <!-- <img src="../assets/down-3.png" style=" z-index: 0;    width: 50%; position: absolute; " class="hidden-sm-and-down" alt=""> -->
        <img src="../assets/map.svg" width="30%" class="mb-3" alt="">
          <p class="my-text-h4 sm-width countryTextBlack px-9" style=" z-index: 1;">اختيار الدولة التي تريد التسوق من متاجرها </p>
        <p class="countryTextBlack my-text-h6 text-center px-11" style="width: ;" >
         تمكنك بوابة الكوبونات من اختيار الدولة التي تريد التسوق من متاجرها, لتحصل على كوبونات خصم بنسب عالية لمتاجرك المفضلة من أي مكان في العالم
        </p>
        <v-btn href="https://chromewebstore.google.com/detail/%D8%A8%D9%88%D8%A7%D8%A8%D8%A9-%D8%A7%D9%84%D9%83%D9%88%D8%A8%D9%88%D9%86%D8%A7%D8%AA-%D9%83%D9%88%D8%A8%D9%88%D9%86%D8%A7%D8%AA-%D9%88/apgcgalijekphibmaeccehlbdmfomgke?hl=en"
            target="_blank"
            style="cursor: pointer; z-index: 2; font-family: 'Cairo', sans-serif; font-weight: bold; width: fit-content; height: 53px; border-radius: 10px;" elevation="3" color="#7DB0C0" class="white--text mt-2">!اختر دولتك وأبدأ الآن بتوفير المال معنا</v-btn>
        <img src="../assets/arrow2.svg" style="rotate: 20deg; margin-right: 200px;" height="250px" alt="">
          </v-col>
        <v-col class="d-flex algin-center justify-center text-center" style="justify-content: center; align-items: center; align-self: center;"  :class="{'col-12': $vuetify.breakpoint.smAndDown, 'col-6': $vuetify.breakpoint.mdAndUp}">
          <img class="mt-5 countryImg" src="../assets/country.svg" width="700px " alt="" style="z-index: 1;">
        </v-col>
      </v-row>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.countryText{
        z-index: 1;
        font-family: 'Cairo' !important;
        font-weight: 700;
        font-size: 22px;
        line-height: 29px;
        text-align: center;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        direction: rtl;
        border-radius: 20%;
}
.countryTextBlack{
        z-index: 1;
        font-family: 'Cairo' !important;
        font-weight: 700;
        font-size: 22px;
        line-height: 29px;
        text-align: center;
        color: black;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        direction: rtl;
        border-radius: 20%;
}
@media (max-width: 600px) {
  .countryImg {
    max-width: 300px !important;
  }
  .countryText{
    z-index: 1;
        font-family: 'Cairo' !important;
        font-weight: 700;
        font-size: 22px;
        line-height: 29px;
        text-align: center;
        color: black;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        direction: rtl;
        border-radius: 20%;
  }
}
</style>
