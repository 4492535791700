<template>
  <div :class="{'px-16': $vuetify.breakpoint.mdAndUp}" class="mb-6" style="margin-top: 100px;">
    <v-row align="center" align-content="center" justify="center">
      <v-col :class="{'col-12': $vuetify.breakpoint.smAndDown, 'col-3': $vuetify.breakpoint.mdAndUp}" class="d-flex justify-center align-center">
        <img src="../assets/desktop mockup.svg" alt="" width="150%" style="margin-left: 100px; margin-bottom: 30px;" class="pa-0 hidden-sm-and-down">
        <img src="../assets/desktop mockup.svg" alt="" width="80%" class="pa-0 hidden-md-and-up">
      </v-col>
      <v-col :class="{'col-3': $vuetify.breakpoint.mdAndUp}" class="d-flex justify-center align-center">
        <img src="../assets/undraw_mobile_pay_re_sjb8.svg" alt="" width="140%"  style="padding-left: 100px; margin-left: 100px; margin-bottom: 30px;" class="hidden-sm-and-down">
        <img src="../assets/undraw_mobile_pay_re_sjb8.svg" alt="" width="80%" class="hidden-md-and-up">
      </v-col>
      <v-col :class="{'col-12 pl-0': $vuetify.breakpoint.smAndDown, 'col-6': $vuetify.breakpoint.mdAndUp}" class="rtl text-center d-flex justify-center align-center flex-column" style="width: 100%; padding-left: 160px;">
        <img src="../assets/yellow.svg" style="position: absolute;" alt="" width="42%" class="hidden-sm-and-down">
        <p class="my-text-h4 sm-width countryTextBlack mr-10" style=" z-index: 1; font-size: 27px !important; font-weight: 600;"> استمتع بالتوفير الآن في كل مكان!</p>
        <p :class="{'px-0 pl-0 mx-0': $vuetify.breakpoint.smAndDown}" class="my-text-h6 countryTextBlack mr-10 px-7" style="padding-left:100px; padding-right: 100px; z-index: 1;">
          أصبحت بوابة الكوبونات متوفرة على أجهزة الAndroid  وال IOS لتكن الحسومات معك في كل مكان
        </p>
        <p class="my-text-h4 countryTextBlack px-16" style="font-size: 25px !important; z-index: 1;">
          حمل التطبيق الآن وقم بزيارة موقعنا!
        </p>
        <v-row>
           <v-col>
              <v-btn
              style="cursor: pointer;
            width: 120px;
            height: 60px;
            background: ;
            border-radius: 9px;
            border: 0px;
            color: white;
            font-family: 'Cairo', sans-serif !important;
            font-weight: 600;
            position: relative;
            z-index: 10;
            font-size: 20px;"
             href="https://apps.apple.com/us/app/%D8%A8%D9%88%D8%A7%D8%A8%D8%A9-%D8%A7%D9%84%D9%83%D9%88%D8%A8%D9%88%D9%86%D8%A7%D8%AA/id1550450925"
             target="_blank"> <img style="width: 50%;" src="../assets/icons8-apple-logo-100.png" alt="">
             </v-btn>
          </v-col>
           <v-col>
             <v-btn
             style="cursor: pointer;
            width: 120px;
            height: 60px;
            background: ;
            border-radius: 9px;
            border: 0px;
            color: white;
            font-family: 'Cairo', sans-serif !important;
            font-weight: 600;
            position: relative;
            z-index: 10;
            font-size: 20px;"
              href="https://play.google.com/store/apps/details?id=net.couponsgate&hl=ar&gl=US"
             target="_blank"> <img style="width: 50%;" src="../assets/icons8-android-100.png" alt="">
            </v-btn>
          </v-col>
          <v-col>
              <v-btn
              href="https://couponsgate.net/"
              target="_blank"
              style="cursor: pointer;
            width: fit-content;
            height: 60px;
            background: rgb(100, 172, 254);
            border-radius: 9px;
            border: 0px;
            color: rgb(255, 255, 255);
            font-family: 'Cairo', sans-serif !important;
            font-weight: bold;
            position: relative;
            z-index: 10;
            font-size: 12px;"> موقع بوابة الكوبونات
             </v-btn>
          </v-col>
          </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
